import Header from "./Header";
import Footer from "./Footer";
import * as React from "react";
import { Container } from "react-bootstrap";

import "./../../styles/styles.scss";

export default function Layout({ children }) {
  return (
    <Container id={"app"}>
      <Header />
      {children}
      <Footer />
    </Container>
  );
}
