import * as React from "react";
import { Col, Row } from "react-bootstrap";
import Layout from "../components/layout/Layout";
import MusicSlider from "../components/MusicSlider";
import Head from "../components/Head";

export default function Index() {
  return (
    <Layout>
      <Head />
      <Row className="content">
        <Col md={8} className="position-relative">
          <div className="dots">
            <div>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
          <div>
            <div className="titles">
              <h2>
                Discover how <br /> you listen
              </h2>
              <h3>
                Explore your music taste profile and <br /> create awesome
                playlists.
              </h3>
            </div>

            <a href="https://plink.ir/4b9Uo" className="download-button">
              <div>
                <img src="/img/logo-white.svg" alt="" />
                <span>Continue with Pilo</span>
              </div>
            </a>
          </div>
        </Col>
        <MusicSlider />
      </Row>
    </Layout>
  );
}
