import React from "react";
import { Helmet } from "react-helmet";

const Head = ({ title }) => {
  return (
    <>
      <Helmet
        defaultTitle="Pilo | Discover how you listen"
        title={title}
        titleTemplate="%s | My website"
      />
    </>
  );
};

export default Head;
