import * as React from "react";
import { Col, Row } from "react-bootstrap";

export default function Footer() {
  return (
    <Row className="footer">
      <Col>
        <div className="d-flex align-items-center">
          <img src="/img/flash.svg" className="img-fluid footer-icon" alt="" />
          <span className="footer-text">Explorer coming soon</span>
        </div>
      </Col>
    </Row>
  );
}
