import axios from "axios";
import * as React from "react";
import { Col } from "react-bootstrap";

import { Thumbs } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

export default function MusicSlider() {
  const [musics, setMusics] = React.useState([]);
  const [currentMusic, setCurrentMusic] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [thumbsSwiper, setThumbsSwiper] = React.useState(null);

  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await axios.get("https://api.pilo.app/api/landing");
      setMusics(res.data.data);
      setCurrentMusic(res.data.data[0]);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  function handleSlideChange(swiper) {
    setCurrentMusic(musics[swiper.activeIndex]);
  }

  React.useEffect(() => {
    fetchData();
  }, []);

  return (
    <Col md={4} className="music-slider">
      {!loading ? (
        <Swiper
          center={true}
          modules={[Thumbs]}
          thumbs={{ swiper: thumbsSwiper }}
          onSlideChange={(swiper) => handleSlideChange(swiper)}
        >
          {musics.map((item, index) => (
            <SwiperSlide key={index}>
              <img
                src={item.image}
                alt={item.title}
                className="music-item img-fluid"
              />
            </SwiperSlide>
          ))}
        </Swiper>
      ) : null}
      {currentMusic != null && (
        <>
          <p className="music-name">{currentMusic.title}</p>
          <p className="artist-name">{currentMusic.artist.name}</p>
        </>
      )}
      <Swiper
        spaceBetween={50}
        slidesPerView={3}
        modules={[Thumbs]}
        watchSlidesProgress
        onSwiper={setThumbsSwiper}
      >
        {musics.map((item, index) => (
          <SwiperSlide key={index}>
            <img
              src={item.image}
              alt={item.title}
              className="music-item img-fluid"
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </Col>
  );
}
