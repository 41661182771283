import * as React from "react";
import { Row, Col } from "react-bootstrap";

export default function Header() {
  return (
    <Row>
      <Col className="header">
        <ul className="list-inline">
          <li className="list-inline-item">
            <div className="logo">
              <img src="/img/logo.svg" alt="pilo" width="50" />
            </div>
          </li>
          <li className="list-inline-item download-li">
            <a href="https://plink.ir/4b9Uo" className="download">
              Download
            </a>
          </li>
        </ul>
      </Col>
    </Row>
  );
}
